import styled from 'styled-components';
import { Theme, normalizeThemeClassName } from '../../styles/themes';

export type Padding = 'Tiny' | 'Small' | 'Medium' | 'Large'

type Props = {
  paddingTop?: Padding
  paddingBottom?: Padding
  theme: Theme
  children?: React.ReactNode
  overflowX?: 'auto' | 'hidden' | 'scroll' | 'visible'
  backgroundImage?: string
  id?: string
}

const containerPaddings = {
  Tiny: '32px',
  Small: '48px',
  Medium: '64px',
  Large: '96px',
};

const containerPaddingsMobile = {
  Tiny: '24px',
  Small: '24px',
  Medium: '32px',
  Large: '48px',
};

type ContainerProps = Required<Pick<Props, 'paddingTop' | 'paddingBottom'>>;

const Background = styled.section<Props>`
  background: var(--theme-background);
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    overflow-x: ${(props) => props.overflowX};
  }
  position: relative;
  background-image: ${(props) => (props.backgroundImage ? (`url(${props.backgroundImage})`) : 'none')};
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position: center bottom;
  @media (max-width: 600px) {
    background-image: none;
  }
`;

const Container = styled.div<ContainerProps>`
  max-width: ${(props) => props.theme.layout.maxWidth};
  margin: 0 auto;
  padding: ${(props) => containerPaddings[props.paddingTop]} 64px ${(props) => containerPaddings[props.paddingBottom]};
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding: ${(props) => containerPaddingsMobile[props.paddingTop]} 24px ${(props) => containerPaddingsMobile[props.paddingBottom]};
  }
`;

export default ({
  paddingTop = 'Medium', paddingBottom = 'Medium', theme, children,
  overflowX = 'visible', backgroundImage, id,
} : Props) => (
  <Background
    className={normalizeThemeClassName(theme)}
    overflowX={overflowX}
    backgroundImage={backgroundImage}
  >
    <Container paddingTop={paddingTop} paddingBottom={paddingBottom} id={id}>
      {children}
    </Container>
  </Background>
);
