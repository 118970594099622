import { gql } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import Image from 'next/image';
import styled from 'styled-components';
import {
  CFImage, CFLink, WithId,
} from '../../lib/types';
import { AutoLink } from '../components/Link';
import ModulePaddingWrapper from '../components/ModulePaddingWrapper';

export const PAGE_LINKS_MODULE_MODULE_TYPE_ID = 'bottom_page_links';

const ArrowIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893C6.90237 0.683417 6.90237 1.31658 7.29289 1.70711L12.5858 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H12.5858L7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.7064 8.70782C15.7088 8.70541 15.7112 8.70298 15.7136 8.70055C15.8901 8.52079 15.9992 8.27463 16 8.003C16 8.002 16 8.001 16 8C16 7.999 16 7.998 16 7.997C15.9996 7.8625 15.9727 7.73425 15.9241 7.61722C15.8764 7.50195 15.8063 7.3938 15.7136 7.29945C15.7112 7.29705 15.7089 7.29466 15.7065 7.29228M15.7065 7.29228L8.70711 0.292893Z" fill="#0072FF" />
  </svg>
);

export type BigBottomLinkCard =
{
  link?: CFLink
  title: string,
  body?: {
    json: Object
  },
  image?: CFImage
}

export type BottomLinksCard = WithId<{
  link?: CFLink
  title: string,
  body?: {
    json: Object
  },
  icon?: CFImage
}>;

type Props = {
  intunioModuleTypeId: typeof PAGE_LINKS_MODULE_MODULE_TYPE_ID,
  heading: string,
  bottomLinksCards?: BottomLinksCard[],
  bigBottomLinkCard: BigBottomLinkCard
}

export const pageLinksModule = gql`
fragment pageLinksModule on PageLinks {
  intunioModuleTypeId
  heading
  bottomLinksCardsCollection(limit: 4) {
    items {
      sys {
        id
      }
      title
      icon {...image}
      body {
        json
      }
      link {...link}
    }
  }
  bigBottomLinkCard {
    title
    body {
      json
    }
    image {...image}
    link {...link}
  }
}`;

const BaseCard = styled.article`
  border-radius: 12px;
  height: 100%;
  background: white;
  box-shadow: ${(props) => props.theme.shadows.hard};
  border: 1px solid var(--theme-border-color);
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  cursor: pointer;
  img {
    width: 100%;
    height: 218px;
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    height: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &:hover {
    border: 1px solid var(--theme-border-color-hover);
  }
`;

type CardStackProps = {
  numCards: number
}

const CardStack = styled.div<CardStackProps>`
  display: grid;
  gap: 24px;
  width: 100%;
  grid-template-columns: ${(props) => (props.numCards < 2 ? '1fr' : '1fr 1fr')};
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    gap: 16px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    grid-template-columns: auto;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    ${BaseCard} {
      grid-template-columns: auto 1fr;
      img {
        height: ${(props) => (props.numCards === 2 ? '232px' : '146px')};
        width: ${(props) => (props.numCards === 2 ? '290px' : '219px')};
      }
    }
  }
`;

const LargeCard = styled(BaseCard)`
  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    min-height: 418px;
    grid-template-rows: 1fr auto;
    grid-template-columns: auto;
    img {
      max-height: 340px;
      border-radius: 0;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      aspect-ratio: 2/1;
    }
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    align-items: center;
  }
`;

const Heading = styled.h2`
  font-size: 32px;
  margin: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    font-size: 28px;
  }
`;

type GridProps = {
  nbrOfCards: number
}

const gridTemplateColumns = (nbrOfCards: number) => {
  if (nbrOfCards <= 2) {
    return '1fr 1fr';
  }
  return '1fr 2fr';
};

const Grid = styled.div<GridProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => gridTemplateColumns(props.nbrOfCards)};
  gap: 24px 32px;
  margin: 0 auto;
  max-width: 1312px;

  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    grid-template-columns: auto;
    max-width: 472px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    gap: 16px;
  }
`;

const CardBody = styled.div`
  padding: 24px;
  grid-template-columns: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
`;

const HeadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardRoot = styled.div<{isAnchor: boolean}>`
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: ${(props) => props.theme.shadows.hard};
  border-radius: 12px;
  border: 1px solid var(--theme-border-color);
  &:hover {
    border: 1px solid ${(props) => (props.isAnchor ? 'var(--theme-border-color-hover)' : 'var(--theme-border-color)')};
  }
`;

const CardText = styled.div`
  flex-grow: 1;
`;

const CardHeading = styled.h3`
  font-size: 21px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 8px;
  color: var(--color-charcoal-v100);

  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    font-size: 18px;
  }
`;

const CardParagraph = styled.div`
  line-height: 1.3;
  color: var(--color-charcoal-v100);
  p {
    margin: 0;
    font-size: 18px;

    @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
      font-size: 16px;
    }
  }
`;

const CardBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 16px;
  color: var(--color-bluescape-v100);
`;

const Icon = styled.div`
  img {
    display: block;
  }
`;

const CardLinkWrapper = styled.a`
  text-decoration: none;
  color: var(--theme-text-color);
  height: 100%;
  width: 100%;

  &:hover {
    color: unset;
  }
`;

const BigCardImage = styled(Image)`
  width: 100%;
  object-fit: cover;
  min-height: 256px;
  max-height: 256px;
  display: flex;
`;

const Card = ({
  icon,
  body,
  link,
  title,
}: BottomLinksCard) => {
  const card = (
    <CardRoot isAnchor={!!link}>
      <CardBody>
        <CardText>
          {title && (
            <CardHeading>{title}</CardHeading>
          )}
          <CardParagraph>
            {body ? documentToReactComponents(body.json as Document) : null}
          </CardParagraph>
        </CardText>
        <CardBottom>
          <div>
            {icon && (
              <Icon>
                <img width="32" height="32" src={icon.url} alt={icon.description} />
              </Icon>
            )}
          </div>
          {link && (
            <ArrowIcon />
          )}
        </CardBottom>
      </CardBody>
    </CardRoot>
  );
  if (link) {
    return (
      <AutoLink as={CardLinkWrapper} {...link}>
        {card}
      </AutoLink>
    );
  }
  return card;
};

const CardContent = ({
  title, body, link, image,
}: BigBottomLinkCard) => (
  <>
    {image && (
      <BigCardImage
        width={image.width}
        height={image.height}
        src={image.url}
        alt={image.description || image.title}
        sizes="(max-width: 525px) 90vw, (max-width: 900px) 470px, (max-width: 1560px): 30vw, 470px"
      />
    )}
    <CardBody>
      <div>
        <CardHeading>{title}</CardHeading>
        <CardParagraph>
          {body ? documentToReactComponents(body.json as Document) : null}
        </CardParagraph>
      </div>
      <CardBottom>
        <div />
        {link && (
          <ArrowIcon />
        )}
      </CardBottom>
    </CardBody>
  </>
);

export default ({
  heading,
  bottomLinksCards = [],
  bigBottomLinkCard,
}: Props) => (
  <ModulePaddingWrapper
    theme="Charcoal Bleach"
    paddingBottom="Large"
  >
    <Wrapper>
      {heading && (
        <HeadContainer>
          <Heading>
            {heading}
          </Heading>
        </HeadContainer>
      )}
      <Grid nbrOfCards={bottomLinksCards.length}>
        {bigBottomLinkCard && bigBottomLinkCard.link ? (
          <AutoLink as={CardLinkWrapper} {...bigBottomLinkCard.link}>
            <LargeCard>
              <CardContent {...bigBottomLinkCard} />
            </LargeCard>
          </AutoLink>
        ) : (
          <LargeCard>
            <CardContent {...bigBottomLinkCard} />
          </LargeCard>
        )}
        <CardStack numCards={bottomLinksCards.length - 1}>
          {bottomLinksCards.map((card) => (
            <Card {...card} key={card.sys.id} />
          ))}
        </CardStack>
      </Grid>
    </Wrapper>
  </ModulePaddingWrapper>
);
