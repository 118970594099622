import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import chunk from 'lodash/chunk';
import Image from 'next/image';
import { Theme } from '../../styles/themes';
import ModulePaddingWrapper, { Padding } from '../components/ModulePaddingWrapper';
import {
  CFImage, CFLink, WithId,
} from '../../lib/types';
import { AutoLink } from '../components/Link';
import ArrowLink from '../components/ArrowLink';

export const ArrowIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893C6.90237 0.683417 6.90237 1.31658 7.29289 1.70711L12.5858 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H12.5858L7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.7064 8.70782C15.7088 8.70541 15.7112 8.70298 15.7136 8.70055C15.8901 8.52079 15.9992 8.27463 16 8.003C16 8.002 16 8.001 16 8C16 7.999 16 7.998 16 7.997C15.9996 7.8625 15.9727 7.73425 15.9241 7.61722C15.8764 7.50195 15.8063 7.3938 15.7136 7.29945C15.7112 7.29705 15.7089 7.29466 15.7065 7.29228M15.7065 7.29228L8.70711 0.292893Z" fill="#0072FF" />
  </svg>
);

const CARD_GRID_GAP = '24px';

export type CardProps = WithId<{
  heading?: string,
  body?: {
    json: Object
  },
  imagePosition: 'Icon' | 'Header' | 'BottomIcon',
  link?: CFLink
  image?: CFImage,
  largeIcon: boolean,
}>;

export const CARDS_MODULE_MODULE_TYPE_ID = 'cards_module';

export type Props = {
  intunioModuleTypeId?: typeof CARDS_MODULE_MODULE_TYPE_ID,
  cardsHeading?: string,
  headingSize?: 'Large' | 'Medium',
  paddingTop?: Padding,
  paddingBottom?: Padding,
  theme?: Theme,
  link?: CFLink,
  cards: Array<CardProps>
  anchorId?: string
}

const CardLinkWrapper = styled.a`
  text-decoration: none;
  height: 100%;
  width: 100%;
  max-width: 472px;
`;

const CardRoot = styled.div<{ isAnchor: boolean }>`
  max-width: 472px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: ${(props) => props.theme.shadows.hard};
  border-radius: 12px;
  border: 1px solid var(--theme-border-color);
  &:hover {
    border: 1px solid ${(props) => (props.isAnchor ? 'var(--theme-border-color-hover)' : 'var(--theme-border-color)')};
  }
`;

const ImageContainer = styled.div`
  img {
    border-radius: 12px 12px 0 0;
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Icon = styled.div`
  img {
    display: block;
  }
`;

const CardHeading = styled.div`
  font-size: 21px;
  font-weight: 400;
  margin-bottom: 8px;
  color: var(--color-charcoal-v100);
`;

const CardParagraph = styled.div`
  line-height: 1.3;
  color: var(--color-charcoal-v80);
  p {
    margin: 0;
  }
`;

const CardBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: var(--color-bluescape-v100);
`;

const CardBody = styled.div`
  padding: 24px;
  flex-grow: 1;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

const CardText = styled.div`
  flex-grow: 1;
`;

const Container = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    gap: 24px;
  }
`;

const HeadingH2 = styled.h2`
  text-align: center;
  margin: 0;
  color: var(--color-charcoal-v100);
`;

const HeadingH3 = styled.h3`
  text-align: center;
  margin: 0;
  color: var(--color-charcoal-v100);
`;

const Card = ({
  image,
  imagePosition,
  body,
  link,
  heading,
  largeIcon,

}: CardProps) => {
  const card = (
    <CardRoot isAnchor={!!link}>
      {image && imagePosition === 'Header' && (
        <ImageContainer>
          <Image
            width={image.width}
            height={image.height}
            src={image.url}
            alt={image?.description || image?.title}
            sizes="(max-width: 600px) 90vw, (max-width: 900px) 472px, (max-width: 1570px) 30vw, 464px"
          />
        </ImageContainer>
      )}
      <CardBody>
        {image && imagePosition === 'Icon' && (
          <Icon>
            <img height={largeIcon ? '48' : '32'} src={image.url} alt={image.description} />
          </Icon>
        )}
        <CardText>
          {heading && (
            <CardHeading>{heading}</CardHeading>
          )}
          <CardParagraph>
            {body ? documentToReactComponents(body.json as Document) : null}
          </CardParagraph>
        </CardText>
        {((image && imagePosition === 'BottomIcon') || (link)) && (
          <CardBottom>
            <div>
              {image && imagePosition === 'BottomIcon' && (
                <Icon>
                  <img width="32" height="32" src={image.url} alt={image.description} />
                </Icon>
              )}
            </div>
            {link && (
              <ArrowIcon />
            )}
          </CardBottom>
        )}
      </CardBody>
    </CardRoot>
  );
  if (link) {
    return (
      <AutoLink as={CardLinkWrapper} {...link}>
        {card}
      </AutoLink>
    );
  }
  return card;
};

const CardBaseGrid = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    gap: 16px;
  }
`;

const CardTwoGrid = styled(CardBaseGrid)`
  height: 0;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
  & > *:first-child {
    justify-self: end;
  }
  & > *:last-child {
    justify-self: start;
  }
`;

const CardThreeGrid = styled(CardBaseGrid)<{ children: React.ReactChild[] }>`
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    grid-template-columns: ${(props) => (props.children.length >= 4 ? '1fr 1fr' : '1fr')};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: 1fr;
  }
`;

const CardGrid = styled(CardBaseGrid)`
  display: flex;
  flex-direction: column;
`;

const CardGridRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    gap: 16px;
  }
`;

const CardGridRowItem = styled.div<{ oddRow: boolean }>`
  width: calc(25% - ${CARD_GRID_GAP} * 3 / 4);
  @media (max-width: 1200px) {
    width: ${(props) => (props.oddRow ? `calc(33% - ${CARD_GRID_GAP} * 2 / 3)` : `calc(50% - ${CARD_GRID_GAP} / 2)`)};
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardsModule = (props: Props) => {
  const chunkLength = (props.cards.length === 9 || props.cards.length === 5) ? 3 : 4;
  const chunks = chunk(props.cards, chunkLength);

  let cardGrid = (
    <CardGrid>
      {chunks.map((cards) => (
        <CardGridRow key={cards.map((c) => c.sys.id).join()}>
          {cards.map((card) => (
            <CardGridRowItem
              key={card.sys.id}
              oddRow={chunkLength === 3}
            >
              <Card {...card} />
            </CardGridRowItem>
          ))}
        </CardGridRow>
      ))}
    </CardGrid>
  );

  if (props.cards.length % 3 === 0 && props.cards.length <= 9) {
    cardGrid = (
      <CardThreeGrid>
        {props.cards.map((card) => (
          <Card key={card.sys.id} {...card} />
        ))}
      </CardThreeGrid>
    );
  }

  if (props.cards.length < 2) {
    cardGrid = (
      <CardTwoGrid>
        {props.cards.map((card) => (
          <Card key={card.sys.id} {...card} />
        ))}
      </CardTwoGrid>
    );
  }

  return (
    <ModulePaddingWrapper
      theme={props.theme ?? 'Bluescape Bleach'}
      paddingTop={props.paddingTop ?? 'Medium'}
      paddingBottom={props.paddingBottom ?? 'Medium'}
      id={props.anchorId}
    >
      <Container>
        {props.cardsHeading && (
          props.headingSize === 'Large' ? (
            <HeadingH2>
              {props.cardsHeading}
            </HeadingH2>
          ) : (
            <HeadingH3>
              {props.cardsHeading}
            </HeadingH3>
          )
        )}
        {cardGrid}
        {props.link && <AutoLink as={ArrowLink} {...props.link} />}
      </Container>
    </ModulePaddingWrapper>
  );
};

export default CardsModule;
